var settings = require( "modules/settings" ),
	velocity = require("velocity-animate/velocity"),
	urlParam = require('modules/urlParam')(),
	mousewheel = require('jquery-mousewheel');





module.exports = function( el ) {
		var $el = $( el );
		$window = $( window ),
		_count = $('.projects-container').children().length - 1,
		_index = $el.data('index');
		
		//setParam('cat', 2);
		
		//console.log('get param ' + getParam('cat'))
		//console.log(_index)
		
		
		
		
		
};
  
var settings = require( "modules/settings" ),
	throttle = require("modules/throttle");

	



module.exports = function( el ) {
		var $el = $( el ),
		$window = $( window );
 

		
		
};
  